import Heading from "../typography/Heading";
import { Container, Row } from "react-bootstrap";
import Cards from "./card/Cards";

export default function Portfolio() {
	return (
		<>
			<Container>
				<Heading title="Portfolio" />
				<hr />
				<Container className=" content">
					<Row>
						<Cards />
					</Row>
				</Container>
			</Container>
		</>
	);
}
