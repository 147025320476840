import Layout from "./components/layout/Layout";
import "./sass/style.scss";

function App() {
	return (
		<>
			<Layout />
		</>
	);
}

export default App;
