export default function HightlightText() {
	return (
		<>
			<h1 className="display-5">
				<span>Believe.. </span>
				<span> in &nbsp;</span>
				<span> what&nbsp;</span>
				<span> you&nbsp;</span>
				<span> do&nbsp;</span>
				<span> &&nbsp;</span>
				<span> Do&nbsp;</span>
				<span> What&nbsp; </span>
				<span> you&nbsp; </span>
				<span> Believe! </span>
			</h1>
		</>
	);
}
