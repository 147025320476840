import Heading from "../typography/Heading";
import { Container, Row, Col } from "react-bootstrap";
import Paragraph from "../typography/Paragraph";
import SubHeading from "../typography/SubHeading";
import SkillLists from "./SkillLists";
import AboutPhoto from "./AboutPhoto";

import myPDF from "../../assets/download-file/Min CV_nunsinee_viken_040223.pdf";

export default function About() {
	return (
		<>
			<Container>
				<Heading title="About Me.." />
				<hr />
				<Container className="content">
					<Row>
						<Col xs={12} lg={4} className="col-content">
							<Paragraph>
								Hi, I am Nunsinee Viken{" "}
								<b>(pronounce: "Nan-Si-Ni" in norwegian)</b> and
								I have finished my studies in{" "}
								<b>front-end web development at NOROFF</b> and I
								am looking for an opportunity to start work as a
								front-end web developer.
							</Paragraph>
							<Paragraph>
								I previously worked as IT/data support, web
								developer with HTML, CSS, Dreamweaver, Photoshop
								and Wordpress and I also worked as a webmaster
								for both a travel company and a online
								newspaper.{" "}
								<b>
									I have a strong knowledge in the tourism
									industry and OTAs(Online Travel Agencies){" "}
								</b>
								as I used to work as a reservation manager in a
								five star hotel.
							</Paragraph>
							<Paragraph>
								<b>After moving to Norway in 2018</b>, I decided
								to study front-end development to improve my
								skills and knowledges as I would like to
								continue working with development of web sites.
								Recently, I finished{" "}
								<b>a digital marketing course </b>and I am also
								improving my <b>Norwegian language</b> (I am at
								the B1 level) with Bergen Læringssenter. I am
								now developing my skills further in Udemy and
								Youtube.
							</Paragraph>
							<Paragraph>
								As a person I am <b>open-minded</b>,{" "}
								<b>multilingual</b>,{" "}
								<b>
									hardworking and find it easy to adopt to new
									environments
								</b>
								, therefore I believe I would be an asset for
								those offering me the opportunity to grow with
								them.
							</Paragraph>
							<Paragraph>
								<b>
									Please feel free to contact me to get to
									know me even more and I look forward hearing
									from you.
								</b>
							</Paragraph>
						</Col>
						<Col xs={12} lg={4}>
							<AboutPhoto />
						</Col>
						<Col xs={12} lg={4}>
							<SubHeading subtitle="Skills & Experience" />
							<SkillLists />
							<a href={myPDF} download="cv_nunsinee_viken.pdf">
								<SubHeading subtitle="Download my resume(CV) here" />
							</a>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
}
